.scrollable-content {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
}

.scrollable-content::-webkit-scrollbar {
    width: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,.3);
    border-radius: 20px;
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0,0,0,.6);
}

@media screen and (max-width: 768px) {
    .table-responsive {
        overflow-x: auto;
        display: block;
    }

    table.table-striped th,
    table.table-striped td {
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
    }

    table.table-striped th:first-child,
    table.table-striped td:first-child {
        max-width: 200px; 
    }
}