.custom-modal .modal-content,
.custom-modal .modal-header {
    border-radius: 0 !important;
    padding: 12px 10px 0px 12px;
}
.custom-modal .btn-close
{
    font-size: 10px;
}
.additional-comments{
    margin-bottom: 20px !important;
     border-radius: 0px !important;
}
